import React from "react";
import products from "../../data/products";
import SEO from "../components/SEO";
import { Hero, Navbar } from "../tailwind";
import { Features } from "../tailwind/Features";
import { Footer } from "../tailwind/Footer";
import { faqHandler } from "../tailwind/faqs/faq.handler";

const DEFAULT_PAGE_SLUG = 'car';

const pageContext = {
  product: products.filter(product => product.slug === DEFAULT_PAGE_SLUG)[0],
};

export default ({ navigate, location }) => {
  const { product: { paths, slug, phone, heading, name } } = pageContext;

  return (
    <div style={{ backgroundColor: '#F9FBFD'}}>
      <SEO title={pageContext.product.name} />
      <Navbar phoneNumber={phone}/>
      <Hero navigate={navigate} location={location} paths={paths} heading={heading}/>
      <Features name={name}/>
      {faqHandler(slug).index()}
      <Footer/>
    </div>
  );
};
